import { NewsletterService } from './services/newsletter.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';

@Component({
  selector: 'app-newslettter',
  templateUrl: './newslettter.component.html',
  styleUrls: ['./newslettter.component.css']
})
export class NewslettterComponent implements OnInit {

  @ViewChild('form') form;

  constructor(private newsletterService: NewsletterService, private alertService: AlertService) { }

  ngOnInit(): void {
  }

  subscribe(formInput) {

    if (formInput.valid) {
      const form = formInput.value;


      this.newsletterService.subscribeToNewsteller(form.email).subscribe(
        (result: any) => {
          this.alertService.successToast(result.message)
        },
        (error: any) => {
          this.alertService.serverError(error)
        }

      );
    }

    else {
      this.activeFormError();
    }
  }

  activeFormError() { // to show error if user click submit without touching the input filed
    const keys = Object.keys(this.form.controls)
    for (const key of keys) {

      this.form.controls[key].markAsTouched();
    }
  }

}
