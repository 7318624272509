import {CartService} from './shared/services/cart/cart.service';
import {FavouriteService} from './shared/services/favourite/favourite.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {MetadataService} from './shared/services/http/metadata/metadata.service';
import {AuthService} from './pages/auth/services/auth-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  title = 'e-commerce';

  constructor(private metadataService: MetadataService,
              private route: ActivatedRoute,
              private router: Router,
              private favouriteService: FavouriteService,
              private cartService: CartService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.seo();
    this.favouriteService.getFav();
    this.cartService.getcart();
    this.authService.autoLogin();
  }

  seo() {
    // let self = this;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        // this.metadataService.updateOgUrl(event['ogUrl']);
        this.metadataService.updateTitle(event['title']);

        this.metadataService.updateDescription(event['title'] + event['description'])
      });
  }
}
