import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from '../../pages/auth/services/auth-service.service';
import {Injectable} from '@angular/core';
import {LocalStorageServiceEncrypt} from 'angular-2-local-storage-encrypt';

@Injectable({providedIn: 'root'})
export class ActivationGuard implements CanDeactivate<boolean> {

  constructor(private authService: AuthService, private localStorage: LocalStorageServiceEncrypt) {
  }

  canDeactivate(component: boolean, currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.user.pipe(take(1), map(user => {
      if (!user || (user && user.activation === 1)) {
        return true;
      } else {
        this.localStorage.remove('user');
        this.authService.user.next(null);
        return true;
      }
    }));
  }
}
