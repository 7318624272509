import {GeneralService} from './../general/general.service';
import {AlertService} from './../alert/alert.service';
import {environment} from './../../../../environments/environment';
import {Price} from './../../model/PriceModel';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './../auth/auth.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Product} from '../../model/ProductModel';
import {ApiLinks} from '../../model/ApiLinks';
import {collect} from 'collect.js';

@Injectable({
  providedIn: 'root'
})
export class FavouriteService {
  user;
  api = ApiLinks;
  url = environment.url;
  fav = new BehaviorSubject<number[]>(null); // to get list of favourite prodcut id
  favProduct = new BehaviorSubject<Product[]>(null); // this var is to get the list of favourite product

  private favouriteProcutIds: number[]; // subscribed variable to local favourite ids  to work with it in this services internal

  constructor(private authService: AuthService,
              private http: HttpClient,
              private alertService: AlertService,
              private generalService: GeneralService) {
    this.authService.user.subscribe(user => {
      this.user = user;
    });
    this.initData();

  }


  //------------------------------save favourite data ----------------------------------------------

  addToFav(product: Product) {
    if (this.user) {
      this.saveFavServer(product);
      this.saveFavlocal(product);
    } else {
      this.saveFavlocal(product);
    }
  }

  saveFavlocal(product: Product) {
    let favouriteProducts;
    this.fav.subscribe(products => {
      // add the new product to the BehaviorSubject then save it in the  local storage
      favouriteProducts = products;
    });
    if (favouriteProducts) { // check if there is already array of ids
      if (favouriteProducts.includes(product.id)) // check if it not already exists to remove
      {
        favouriteProducts.splice(favouriteProducts.indexOf(product.id), 1); // delete it from the db
      } else {
        favouriteProducts.push(product.id);
      }
    } else { // if there is no data in storage then we need to start an array
      favouriteProducts = [product.id];
    }


    this.fav.next(favouriteProducts); // then add it to behaviourSubject to access it
    localStorage.setItem('products_fav', JSON.stringify(favouriteProducts));
  }

  saveFavServer(product: Product) {
    this.http.post(this.url + this.api.favourite, {product_id: product.id}).subscribe(
      (result: any) => {
        // this.alertService.successToast('product has been added successfully')
        this.getFav();
        // this.alertService.successToast(this.translateService.instant('favorite.product has been added successfully'))

      },
      (error: any) => {
        this.alertService.serverError(error);
      }
    );
  }

  //------------------------------end save favourite data ----------------------------------------------

  //------------------------------get favourite data ----------------------------------------------
  getFav() {
    if (this.user) {
      return this.getFavFromServer();
    } else {
      return this.getFavFromLocal();

    }
  }

  getFavFromServer() {
    this.http.get(this.url + this.api.getFavourite).subscribe(
      (result: Product[]) => {
        this.favProduct.next(result);
      },
      (error: any) => {
        this.alertService.serverError(error);
      }
    );
  }

  getFavFromLocal() {
    this.fav.subscribe(ids => { // get local id of favourite product
      if (ids) {
        this.generalService.getListOfProduct(ids); // get array list of product
        console.log('atef bardo');

        this.generalService.products.subscribe(product => this.favProduct.next(product)); // get subscribed data form services
      }
    }); // list of products ids
  }

  //------------------------------end get favourite data ----------------------------------------------


  //---------------------------------- is fav or not
  isFav(product: Product): boolean {
    if (this.user) {
      let isFav = false;

      collect(this.favProduct.value).map(products => {
        if (products.id == product.id) {
          isFav = true;
        }
      });

      return isFav;

    } else {
      return this.favouriteProcutIds.includes(product.id);
    }
  }

  initData() { // set intial value of user

    let localProduct = JSON.parse(localStorage.getItem('products_fav'));
    if (localProduct) {
      this.fav.next(localProduct);
    } else {
      this.fav.next([]);
    }
    this.fav.subscribe(ids => this.favouriteProcutIds = ids);
  }

  cleanLocalFav(): void {
    this.fav.next([]);
    JSON.stringify(localStorage.setItem('products_fav', JSON.stringify([])));
  }


}
