import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.css'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class PasswordInputComponent implements OnInit {
  @Input() cols = 'col-lg-6 col-12';
  @Input() name: string;
  @Input() placeholder: string;
  @Input() error: string;
  @Input() showSubmitError: boolean;
  @Input() isRequired = true;
  @Input() minLength = 8;
  @Input() maxLength = 50;
  @Input() model = 50;
  type = 'password';
  icon = 'fa-eye-slash';

  constructor() {
  }

  ngOnInit(): void {
  }

  togglePassword(): void {
    this.type = this.type === 'text' ? 'password' : 'text';
    this.icon = this.icon === 'fa-eye-slash' ? 'fa-eye' : 'fa-eye-slash';
  }

}
