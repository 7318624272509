import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-submit-btn',
  templateUrl: './submit-btn.component.html',
  styleUrls: ['./submit-btn.component.css']
})
export class SubmitBtnComponent {
  @Input() color: string;
  @Input() bgColor: string;
  @Input() type: string;
  @Input() classes: string;
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() text = 'Submit';
  @Output() isLoading = new EventEmitter<boolean>();

  constructor() {
  }

  showLoading(): void {
    this.loading = true;
    this.isLoading.emit(this.loading);
  }

}
