import { CartService } from './../../services/cart/cart.service';
import { CartResponse } from './../../model/CartModel';
import { SharedDataService } from 'src/app/shared/SharedData/shared-data.service';
import { FavouriteService } from './../../services/favourite/favourite.service';
import { GeneralService } from './../../services/general/general.service';
import { environment } from './../../../../environments/environment';
import { Product } from './../../model/ProductModel';
import { Component, Input, OnInit } from '@angular/core';
import { env } from 'process';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

  @Input('product') product: Product;

  url = environment.url;

  constructor(private favouriteService: FavouriteService, private cartService: CartService, public sharedDataService: SharedDataService) { }

  ngOnInit(): void {
  }

  addToFavorite() {
    this.favouriteService.addToFav(this.product);

    this.checkFav();

  }

  addToCart() {
    this.cartService.addTocart(this.product,1);

    this.checkInCart();

  }

  
  removeFromCart() {
    this.cartService.addTocart(this.product,0);

    this.checkInCart();

  }

  checkFav(): boolean {
    // console.log(product.id);
    return this.favouriteService.isFav(this.product);
  }

  checkInCart(): boolean {
    // console.log(product.id);
    return this.cartService.inCart(this.product);
  }

}
