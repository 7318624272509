<!--------------------------------------------------------------------------
---------------------------------- Product ------------------------------------
--------------------------------------------------------------------------->
<section class="py-3 pt-lg-0" id="product">
    <div class="container">
        <div>

            <main class="mt-lg-2 mt-0 mt-xl-2">
                <h6 class="secondary d-inline-block fs-9">SHOP</h6>
                <i class="fas fa-chevron-right primary fs-8 px-2"></i>
                <h6 class="secondary d-inline-block fs-9 text-capitalize">
                    {{pageObj?.product?.subcategory?.category?.name_en}}</h6>
                <i class="fas fa-chevron-right primary fs-8 px-2"></i>
                <h6 class="secondary d-inline-block fs-9 text-capitalize">{{pageObj?.product?.subcategory?.name_en}}
                </h6>
                <i class="fas fa-chevron-right primary fs-8 px-2"></i>
                <h6 class="secondary d-inline-block fs-9 text-capitalize">{{pageObj?.product!['name_'+
                    sharedDataService.currentLang]}}</h6>
                <hr class="mt-1 mb-3">
            </main>

            <div class="row">


                <div class="col-xl-6 col-lg-8 offset-lg-2 offset-0 offset-xl-0">
                    <div class="row">

                        <div class="col-lg-2 col-3 pr-0">
                            <ng-container *ngFor="let image of imagesSrcArray; let i=index">

                                <div class="mb-3  position-relative attachment-holder overflow-hidden radius-11"
                                    [ngClass]="{'active': image.image==imageSrc}">
                                    <img (click)="changeImage(image.image)" [src]=" url+imagesSrcArray[i].image" alt=""
                                        class="img-fluid radius-10 attachment-image border">
                                </div>

                            </ng-container>

                        </div>

                        <div class="col-lg-10 col-9">
                            <img [src]="url+imageSrc" alt="{{pageObj?.product['name_'+sharedDataService.currentLang]}}"
                                class="img-fluid radius-10 cover-image radius-16 w-100 border">
                        </div>

                    </div>
                </div>

                <div class="col-xl-6 mt-xl-0 mt-3">
                    <div class="row px-0">

                        <div class="col-md-6 px-lg-0 order-lg-2 order-xl-1 order-1">
                            <div class="mb-3">
                                <h6 class="secondary segoe-samibold">
                                    {{pageObj?.product!['name_'+sharedDataService.currentLang]}}</h6>
                                <p class="grey text-justify"
                                    [innerHtml]='pageObj?.product!["description_"+ sharedDataService.currentLang]'>

                                </p>
                            </div>
                            <div class="d-flex justify-content-between mb-2">
                                <div class="mt-auto">
                                    <ngb-rating [max]='5' [(rate)]="currentRate" class="yellow fs-1-5">
                                    </ngb-rating>
                                </div>
                                <div class=" my-auto">
                                    <p class="grey mb-0">Product ID # {{pageObj?.product?.id}}</p>
                                </div>
                            </div>
                            <hr class="mt-0 mb-2">
                            <div class="text-center">
                                <h5 class="offwhite-bg primary segoe-samibold d-inline-block py-1 px-3 radius-6">
                                    ${{pageObj?.product?.price?.price}}
                                </h5>
                                <p
                                    class="grey-bg secondary segoe-samibold d-inline-block py-1 px-3 radius-6 line-through">
                                    $??
                                </p>
                                <button
                                    class="btn grey-bg secondary segoe-samibold d-inline-block py-1 px-3 radius-6 line-through">
                                    <i class="far fa-heart primary"></i>
                                </button>
                            </div>
                            <hr class="my-0">
                            <div class="text-center">
                                <span class="product-label-internal text-white red-bg px-2 radius-50 mr-2 py-1"
                                    *ngIf="pageObj?.product?.hot_deals">
                                    <i class="fas fa-fire label-size"></i>
                                    <span>Hot deals</span>
                                </span>
                                <!-- <span class="product-label-internal text-input-white green-bg px-2 radius-50  mr-2 py-1" *ngIf="pageObj?.product?.">
                                    <i class="fas fa-shipping-fast label-size"></i>
                                    <span>Free delivery</span>
                                </span> -->
                                <!-- <span class="product-label-internal text-input-white yellow-bg px-2 radius-50 new-label py-1">
                                    <img src="assets/icons/Group 5642@2x.png" class="d-inline-block" alt="">
                                    <span>New</span>
                                </span> -->
                            </div>
                            <hr class="mt-1">
                            <div class="text-xl-left text-center">
                                <label class="grey font-weight-bold mr-2 mb-0 d-xl-inline-block d-none">
                                    Share
                                </label>
                                <button class="btn offwhite-bg px-3">
                                    <i class="fab fa-facebook-f secondary"></i>
                                </button>
                                <button class="btn offwhite-bg px-3">
                                    <i class="fab fa-twitter secondary"></i>
                                </button>
                                <button class="btn offwhite-bg px-3">
                                    <i class="fab fa-instagram secondary"></i>
                                </button>
                                <button class="btn offwhite-bg px-3">
                                    <i class="fab fa-linkedin secondary"></i>
                                </button>
                            </div>
                            <hr>
                            <div class="d-flex">
                                <label class="grey font-weight-bold mr-2">
                                    quantity
                                </label>
                                <button class="btn offwhite-bg px-3 decrementer" [disabled]="quantity==1"
                                    (click)="updateQuantitiy('decrease')">
                                    <i class="fas fa-minus "
                                        [ngClass]="{'primary': quantity>1,'grey' : quantity==1}"></i>
                                </button>
                                <div class="d-inline-block px-2">
                                    <input type="text" class="form-control text-center bg-white" max="100" min="1"
                                        id="productsCount" [(ngModel)]="quantity" readonly>
                                </div>
                                <button class="btn offwhite-bg px-3 incrementer" [disabled]="quantity==maxQuantity"
                                    (click)="updateQuantitiy('increase')">
                                    <i class="fas fa-plus primary"></i>
                                </button>
                            </div>
                            <div>
                                <button class="btn primary-bg btn-block mt-3 radius-6">
                                    <i class="fas fa-shopping-cart text-white"></i>
                                    <span class="text-white">Add to cart</span>
                                </button>
                            </div>
                        </div>

                        <div class="col-md-6 mt-4 mt-md-0  order-lg-1 order-xl-2 order-2">

                            <div class="offwhite-bg p-2 radius-11">
                                <div class="mb-3">
                                    <h6 class="secondary segoe-samibold">Vendor/brand name</h6>
                                </div>
                                <hr>
                                <div>
                                    <div class="d-flex justify-content-between">
                                        <div class="my-auto">
                                            <span class="secondary font-weight-bold mr-2">Reviews</span>

                                            <ngb-rating [max]='5' [(rate)]="currentRate" class="yellow fs-1-5">
                                            </ngb-rating>
                                        </div>
                                        <div class="my-auto">
                                            <p class="secondary font-weight-bold mb-0">2.3K</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="btn btn-block mt-3 radius-6 border-primary">
                                            <span class="primary">Follow</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <br>
                            <div class="position-relative">

                                <img [src]="url+ pageObj?.product?.default_image" class="img-fluid w-100 radius-11"
                                    alt="{{pageObj.product['name_'+ sharedDataService.currentLang]}}">

                                <a href="#" Title="Link Title"
                                    class="btn bg-white primary radius-6 shadow-sm position-absolute shop-now-slider-position p-0">
                                    <i class="fas fa-home p-2"></i>
                                    <span class="btn-vertical-separetor"></span>
                                    <span class="p-2"> SHOP NOW</span>
                                </a>
                            </div>
                        </div>


                    </div>

                </div>

            </div>


        </div>

    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------Product------------------------------------
--------------------------------------------------------------------------->


<!--------------------------------------------------------------------------
---------------------------------- Product description ------------------------------------
--------------------------------------------------------------------------->
<section class="py-3" id="productDescription">



    <app-overview-description [description]='pageObj.product["long_description_"+sharedDataService.currentLang]'>
    </app-overview-description>

</section>
<!--------------------------------------------------------------------------
----------------------------------Product description------------------------------------
--------------------------------------------------------------------------->



<!--------------------------------------------------------------------------
---------------------------------- New Arrival ------------------------------------
--------------------------------------------------------------------------->
<section class="offwhite-bg py-4">
    <div class="container">
        <div class="row px-3">

            <div class="mb-2 pb-2">
                <h1 class="segoe-bold mb-0">Similar items</h1>
                <span class="title-separator"></span>
            </div>

            <div class="col-12 px-0">
                <app-products-carousel [products]='pageObj?.similar_products'></app-products-carousel>
            </div>

        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------New Arrival------------------------------------
--------------------------------------------------------------------------->



<!--------------------------------------------------------------------------
---------------------------------- newsletter ------------------------------------
--------------------------------------------------------------------------->
<section class="newsletter py-5  primary-bg">
    <app-subscribe-to-news></app-subscribe-to-news>
</section>
<!--------------------------------------------------------------------------
----------------------------------newsletter------------------------------------
--------------------------------------------------------------------------->
