<div class="position-relative">

    <div class="ratio half-top-radius-9" [ngStyle]="{'background-image': 'url('+url + blog?.image+ ')'}">
    </div>

    <div class="pt-2 px-lg-2 grey-bg" *ngIf="blog">
        <h6 class="secondary segoe-samibold">{{blog['title_'+ sharedDataService.currentLang] |truncate:[50,'...']}}</h6>
        <hr class="my-1">
        <p class="grey" [innerHtml]='blog["content_"+ sharedDataService.currentLang]|truncate:[150,"..."]'></p>
    </div>
</div>