import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  public currentLang: string = '';
  constructor() {
    let lang = localStorage.getItem('lang') || '';
    if (lang == '') {
      this.currentLang = 'en';
    }
    else {
      this.currentLang = lang;
    }
  }


}
