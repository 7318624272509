export class User {
  constructor(public id: number,
              public name: string,
              public email: string,
              public phone: string,
              public activation: number,
              public image: string,
              public type: number,
              private api_token: string,
              public cart_count: number,
              public gender?: any,
              public social_id?: any,
              public social_type?: any,
              public city_id?: any,
  ) {
  }

  get token(): string {
    return this.api_token;
  }

}
