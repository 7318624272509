<div class="input-group">
  <label class="w-100" [textContent]="placeholder" [for]="name"></label>

  <input [placeholder]="placeholder" [required]="isRequired" [minlength]="minLength"
         [maxlength]="maxLength" [name]="name" [id]="name" [type]="type"
         class="form-control radius-right-6" ngModel #model="ngModel">

  <div class="input-group-append">
    <span class="input-group-text bg-white pointer" (click)="togglePassword()">
      <i class="fas" [class]="icon"></i>
    </span>
  </div>

  <small *ngIf="error" class="w-100 form-text text-danger">{{ error}}</small>
  <small *ngIf="((model.invalid && model.touched) || (showSubmitError && model.invalid)) && !error" class="w-100 form-text text-danger">This field is required with min length {{ minLength }}</small>
</div>

