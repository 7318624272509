import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthResponseModel} from '../../models/AuthResponseModel';
import {AuthService} from '../../services/auth-service.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AlertService} from '../../../../shared/services/alert/alert.service';
import {ForgetPasswordEnum} from '../../../../shared/enums/forget-password-enum';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent {
  @ViewChild('sendCodeForm') sendCodeForm;
  @ViewChild('ResetForm') ResetForm;
  errors = {
    phone: null,
    code: null,
    message: null,
    password: null,
    passwordConfirmation: null,
  };
  btnLoading: boolean;
  showSubmitError: boolean;
  forgetPasswordEnums = ForgetPasswordEnum;
  formType = ForgetPasswordEnum.forgetPassword;
  phone: number;

  constructor(private authService: AuthService,
              private ngxLoader: NgxUiLoaderService,
              private alertService: AlertService,
              private router: Router) {
  }

  submitSendCodeForm(SendCodeform: NgForm): void {
    if (SendCodeform.invalid) {
      this.showSubmitError = true;
      this.btnLoading = false;
      return;
    }
    SendCodeform.form.setErrors({invalid: true});
    this.authService.sendResetPasswordCode(SendCodeform.value).subscribe(response => {
      this.alertService.successToast(response.message);
      this.phone = this.sendCodeForm.form.value.phone;
      this.sendCodeForm.reset();
      this.btnLoading = false;
      this.formType = this.forgetPasswordEnums.ResetPassword;
    }, error => this.handleResponseError(error.error.errors, SendCodeform));
  }

  submitResetForm(Resetform: NgForm): void {
    if (Resetform.invalid) {
      this.showSubmitError = true;
      this.btnLoading = false;
      return;
    }
    if (Resetform.value.password !== Resetform.value.password_confirmation) {
      this.errors.password = 'Password confirmation not matched';
      this.btnLoading = false;
      return;
    }
    Resetform.form.setErrors({invalid: true});
    console.log(this.phone);
    Resetform.form.value.phone = this.phone;
    this.authService.resetPasswordCode(Resetform.value).subscribe(() => this.router.navigate(['/login']),
      error => {
        this.errors.code = error.error.phone;
        Resetform.form.setErrors(null);
        this.showSubmitError = false;
        this.btnLoading = false;
        this.ngxLoader.stopAll();
      });
  }

  private handleResponseError(errors, form): void {
    for (const error of errors) {
      this.errors[error.name] = error.message;
    }
    form.form.setErrors(null);
    this.showSubmitError = false;
    this.btnLoading = false;
    this.ngxLoader.stopAll();
  }


}
