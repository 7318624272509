<div class="form-group">

  <label [textContent]="placeholder" [for]="name"></label>

  <input numbersOnly type="text" class="form-control" [name]="name" [id]="name"
         [placeholder]="placeholder" [required]="isRequired" [minlength]="minLength"
         [maxlength]="maxLength" ngModel #model="ngModel">

  <small *ngIf="error" class="form-text text-danger w-100">{{ error}}</small>
  <small *ngIf="((model.invalid && model.touched) || (showSubmitError && model.invalid)) && !error" class="form-text text-danger w-100">This field is not valid</small>

</div>
