<app-center-card>
  <form (ngSubmit)="submitRegister(registerForm)" #registerForm="ngForm" class="row px-3">
    <app-text-input class="col-lg-6 col-12" [minLength]="1" name="name"
                    placeholder="Full Name" [error]="errors.name"></app-text-input>

    <app-number-input class="col-lg-6 col-12" name="phone" placeholder="Phone"
                      [minLength]="10" [maxLength]="10"
                      [error]="errors.phone"></app-number-input>


    <app-email-input class="col-lg-6 col-12" [minLength]="1" name="email"
                     placeholder="Email Address" [error]="errors.email"
                     [showSubmitError]="showSubmitError"></app-email-input>

    <app-password-input class="col-lg-6 col-12" name="password"
                        placeholder="Password" [error]="errors.password"
                        [showSubmitError]="showSubmitError"></app-password-input>

    <app-password-input class="col-lg-6 col-12" name="password_confirmation"
                        placeholder="Password Confirmation" [error]="errors.passwordConfirmation"></app-password-input>

    <app-submit-btn class="col-12 text-lg-right text-center" type="submit" [disabled]="registerForm.invalid"
                    [loading]="btnLoading" (isLoading)="btnLoading = $event" bgColor="primary-bg"
                    color="text-white"></app-submit-btn>

    <div class="col-12">
      <h6>
        <span>Already have account</span>&nbsp;
        <a routerLink="/login">Login Now</a>
      </h6>
    </div>

  </form>
</app-center-card>
