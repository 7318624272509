<div class="product-item product-item overflow-hidden radius-5">
    <div class="position-relative">
        <img [src]="url+ product?.default_image" alt="" class="img-fluid product-image radius-top-16">
        <span class="text-white yellow-bg px-2 radius-50 position-absolute new-label">
            <img src="assets/icons/Group 5642@2x.png" class="d-inline-block" alt="">
            <span>New</span>
        </span>
        <div class="position-absolute marks-label-container">
            <span class="text-white red-bg px-2 radius-50 mr-2 pb-1">
                <i class="fas fa-fire label-size"></i>
                <span>Hot deals</span>
            </span>
            <span class="text-white green-bg px-2 radius-50 pb-1">
                <i class="fas fa-shipping-fast label-size"></i>
                <span>Free delivery</span>
            </span>
        </div>
        <span class="px-2 radius-50 position-absolute product-fav-icon pointer">
        <i  class="far fa-heart primary fa-1x" [ngClass]="{'fas': checkFav()}" (click)="addToFavorite()"></i>
        </span>
    </div>

    <div class="mt-0 bg-white radius-bottom-16">
        <h6 class="px-2 float-left secondary font-weight-bold py-2 segoe-samibold mb-0">
            {{product['name_'+sharedDataService.currentLang]}}</h6>
        <div class="clearfix"></div>
        <div>
            <p class="grey px-2 description mb-0" > {{product['description_'+sharedDataService.currentLang]|cleanHtml |
                truncate:[100,'...']}}</p>
            <hr class="my-0">
            <h6 class="px-2 text-center segoe-bold dark-white-bg p-2">
                <ng-container *ngIf="product.today_offer">
                    <small class="line-through segoe-regular">{{product.price.price}}
                        {{product.price.country['nam_'+sharedDataService.currentLang]}}</small>
                    /
                </ng-container>

                <span *ngIf="product.today_offer"> {{product.today_offer}}
                    {{product.price.country['currency_'+sharedDataService.currentLang]}}</span>
                <span *ngIf="!product.today_offer">{{product.price.price}}
                    {{product.price.country['currency_'+sharedDataService.currentLang]}}</span>
            </h6>

            <button *ngIf="!checkInCart();" (click)="addToCart()" class="primary-bg border-0 radius-bottom-16 py-2 w-100 text-white">
                <i class="fas fa-cart-plus white px-2"></i>
                <span>Add to cart</span>
            </button>
            <button *ngIf="checkInCart()" (click)="removeFromCart()" class="red border-0 radius-bottom-16 py-2 w-100 bg-white border-red">
                <i class="fas fa-cart-arrow-down px-2"></i>
                <span>remove from cart</span>
            </button>
        </div>
    </div>
</div>