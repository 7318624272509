import { environment } from './../../../../../environments/environment';
import { SharedDataService } from './../../../../shared/SharedData/shared-data.service';
import { Category } from './../../../../shared/model/CategoryModel';
import { Component, Input, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  @Input('category') category: Category
  url = environment.url

  constructor(public sharedDataService: SharedDataService) { }

  ngOnInit(): void {
    this.hoverCategory();
  }

  hoverCategory() {
    // category images script
    $('.category-overlay').fadeOut(1)
    $('.category-overlay').next('h1').fadeOut(1)

    $('.category-container').hover(function () {
      $(this).find('.category-overlay').fadeIn()
      $(this).find('.category-overlay').next('h1').fadeIn()
    }, function () {
      $(this).find('.category-overlay').fadeOut()
      $(this).find('.category-overlay').next('h1').fadeOut()
    })

  }

}
