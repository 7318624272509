import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-center-card',
  templateUrl: './center-card.component.html',
  styleUrls: ['./center-card.component.css']
})
export class CenterCardComponent implements OnInit {
  @Input() bgColor = 'bg-white';

  constructor() {
  }

  ngOnInit(): void {
  }

}
