import { SharedDataService } from './../../SharedData/shared-data.service';
import { environment } from 'src/environments/environment';
import { Slider } from './../../../pages/home/model/HomeResponseModel';
import { Product } from './../../model/ProductModel';
import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css']
})
export class ImageSliderComponent implements OnInit {

  constructor(public sharedDataService:SharedDataService) { }

  @Input('slider') sliders: Slider[];
  url = environment.url;
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    // responsiveClass: true,
    dots: true,
    autoplay: true,
    nav: false,
    navText: [
      '<i class="fas fa-chevron-left grey fa-2x"></i>',
      '<i class="fas fa-chevron-right grey fa-2x"></i>'
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    }
  }


  ngOnInit(): void {
  }

}
