import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  constructor() { }

  successToast(message) {
    this.Toast.fire({
      icon: 'success',
      background: '#28a745',
      iconColor: 'white',
      html: '<span class="text-input-white mx-2">' + message + '<span>'
    })
  }


  serverError(serverError) {
    if (serverError.status == 422) {
      serverError.error.errors.forEach(error => {
        this.errorToast(error.message)
      });
    }
    else if (serverError.status == 401) {
      this.errorToast(serverError.error.error)
    }

  }
  errorToast(message) {
    this.Toast.fire({
      icon: 'error',
      background: '#FF0000',
      iconColor: 'white',
      html: '<span class="text-input-white mx-2">' + message + '<span>'
    })

  }
}
