<!-- <div class="owl-carousel owl-carousel-slider">

    <div class="radius-11 overflow-hidden position-relative">
        <img src="assets/images/p5jd9qat62pJcy8exmruxI4NZlHZnQOi7SYk81bE.png" alt="">
        <a href="#" Title="Link Title"
            class="btn bg-white primary radius-6 shadow-sm position-absolute shop-now-slider-position p-0">
            <i class="fas fa-home p-2"></i>
            <span class="btn-vertical-separetor"></span>
            <span class="p-2"> SHOP NOW</span>
        </a>
    </div>

    <div class="radius-11 overflow-hidden position-relative">
        <img src="assets/images/p5jd9qat62pJcy8exmruxI4NZlHZnQOi7SYk81bE.png" alt="">
        <a href="#" Title="Link Title"
            class="btn bg-white primary radius-6 shadow-sm position-absolute shop-now-slider-position p-0">
            <i class="fas fa-home p-2"></i>
            <span class="btn-vertical-separetor"></span>
            <span class="p-2"> SHOP NOW</span>
        </a>
    </div>




</div> -->

<owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slider of sliders;">
        <ng-template carouselSlide>
            <div class="radius-11 overflow-hidden position-relative">
                <img [src]=" url + slider.image" [alt]='slider["sub_title_"+sharedDataService.currentLang]'>
                <a href="#" Title="Link Title"
                    class="btn bg-white primary radius-6 shadow-sm position-absolute shop-now-slider-position p-0">
                    <i class="fas fa-home p-2"></i>
                    <span class="btn-vertical-separetor"></span>
                    <span class="p-2"> SHOP NOW</span>
                </a>
            </div>
        </ng-template>
    </ng-container>

    
</owl-carousel-o>