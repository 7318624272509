<div class="container">
    <div class="row align-items-center">
        
        <div class="col-lg-6 my-3 my-lg-0 text-center text-lg-left">
            <div>
                <h4 class="segoe-samibold text-white">SUBSCRIBE TO OUR NEWSLETTER</h4>
                <h6 class="segoe-samibold text-white">Sign up for newsletter and Get free offer</h6>
            </div>
        </div>

        <div class="col-lg-6">
            <div>
                <form class="form-inline position-relative input-bg">
                    <input class="form-control shadow-sm radius-11 primary-border col-lg-12 col" type="search" placeholder="example@mail.com" aria-label="Search">
                    <button class="btn bg-white primary px-4 radius-11 position-absolute" type="submit">
                        Subscribe
                    </button>
                </form>
            </div>
        </div>


    </div>
</div>