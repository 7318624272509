export const ApiLinks = {
  getProductById: 'api/v1/user/app/product/',
  home: 'api/v1/user/app/home',
  subscribeToNewsteller: 'api/v1/user/app/email-subscribe',
  favourite: 'api/v1/user/app/action/favourite',
  cart: 'api/v1/user/app/cart/add',
  getFavourite: 'api/v1/user/auth/favourite',
  getcart: 'api/v1/user/app/cart/get-carts',
  productList: 'api/v1/user/app/get-products',
  // auth
  signin: 'api/v1/user/auth/signin',
  signup: 'api/v1/user/auth/signup',
  activation: 'api/v1/user/auth/active-account',
  sendPasswordCode: 'api/v1/user/auth/send-reset-password-code',
  resetPassword: 'api/v1/user/auth/reset-password',

};
