import { HomeResponseModel } from './../model/HomeResponseModel';
import { HomeService } from './home.service';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
    providedIn: 'root'
})
export class HomeResolver implements Resolve<HomeResponseModel>{
    constructor(private homeService: HomeService, private ngxLoader: NgxUiLoaderService,) {
        // this.ngxLoader.start();
        this.ngxLoader.start();


    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HomeResponseModel> | Promise<HomeResponseModel> | HomeResponseModel {
        
        let home = this.homeService.getHome();
        // this.ngxLoader.stopAll();
        return home;
    }
}
