import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class NumberInputComponent {
  @Input() cols = 'col-lg-6 col-12';
  @Input() name: string;
  @Input() placeholder: string;
  @Input() error: string;
  @Input() showSubmitError: boolean;
  @Input() isRequired = true;
  @Input() minLength = 1;
  @Input() maxLength = 50;

  constructor() {
  }

}
