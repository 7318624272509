import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormGroup, NgForm} from '@angular/forms';

@Component({
  selector: 'app-text-input',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class TextInputComponent implements OnInit {
  @Input() cols = 'col-lg-6 col-12';
  @Input() name: string;
  @Input() formGroup: FormGroup;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() error: string;
  @Input() showSubmitError: boolean;
  @Input() isRequired = true;
  @Input() minLength = 0;
  @Input() maxLength = 50;

  constructor() {
  }

  ngOnInit(): void {
  }

}
