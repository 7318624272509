<div class="d-lg-flex justify-content-between">

    <div>
        <div class="row">
            <div class="col-3 mb-3 mb-lg-0">
                <div class="bg-white radius-11 p-3 img-container">
                    <img src="assets/icons/fast@2x.png" title="FREE DELIVERY From $59.89"
                        class="bg-white basic-icon-size" alt="">
                </div>
            </div>
            <div class="col">
                <h4 class="segoe-bold text-white">FREE DELIVERY</h4>
                <h6 class="segoe-samibold text-white"> {{setting['delivery_price_'+sharedDataService.currentLang]}}</h6>
            </div>
        </div>
    </div>

    <div>
        <div class="row">
            <div class="col-3 mb-3 mb-lg-0 mr-2">
                <div class="bg-white radius-11 p-3 img-container">
                    <img src="assets/icons/Group3@2x.png" title="FREE DELIVERY From $59.89"
                        class="bg-white basic-icon-size" alt="">
                </div>
            </div>
            <div class="col">
                <h4 class="segoe-bold text-white">SUPPORT 24/7</h4>
                <h6 class="segoe-samibold text-white"> {{setting['support_hours_'+sharedDataService.currentLang]}}</h6>
            </div>
        </div>
    </div>

    <div>
        <div class="row">
            <div class="col-3 mr-3">
                <div class="bg-white radius-11 p-3 img-container">
                    <img src="assets/icons/Grou-@2x.png" title="FREE DELIVERY From $59.89"
                        class="bg-white basic-icon-size" alt="">
                </div>
            </div>
            <div class="col">
                <h4 class="segoe-bold text-white">BIG SAVING </h4>
                <h6 class="segoe-samibold text-white"> {{setting['free_return_'+sharedDataService.currentLang]}}</h6>
            </div>
        </div>
    </div>


</div>