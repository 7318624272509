import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscribe-to-news',
  templateUrl: './subscribe-to-news.component.html',
  styleUrls: ['./subscribe-to-news.component.css']
})
export class SubscribeToNewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
