<div class="row align-items-center">

    <div class="col-lg-6 my-3 my-lg-0 text-center text-lg-left">
        <div>
            <h4 class="segoe-samibold text-white">SUBSCRIBE TO OUR NEWSLETTER</h4>
            <h6 class="segoe-samibold text-white">Sign up for newsletter and Get free offer</h6>
        </div>
    </div>

    <div class="col-lg-6">
        <div>
            <form #form="ngForm"  class="form-inline position-relative">
                <!-- <input class="form-control shadow-sm radius-11 primary-border col-lg-12 col" type="search"
                    placeholder="example@mail.com"  aria-label="Search">
 -->

                <input  type="email" id="email"
                    class="form-control shadow-sm radius-11 primary-border col-lg-12 col" (keyup.enter)='subscribe(form)' #email='ngModel'
                    ngModel name="email" required placeholder="Email Address"  placeholder="example@mail.com"  aria-label="Search"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}$">

                <div class="error-message" *ngIf="email.invalid && (email.dirty || email.touched)">

                    <div *ngIf="email.errors.required"> Email is required </div>
                    <div *ngIf="email.errors.pattern"> Please enter a valid email address.
                    </div>


                </div>
                <button  class="btn bg-white primary px-4 radius-11 position-absolute" (click)="subscribe(form)" type="submit">
                    Subscribe
                </button>
            </form>
        </div>
    </div>


</div>