import { SharedDataService } from './../../../../shared/SharedData/shared-data.service';
import { Settings } from './../../model/HomeResponseModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  @Input('setting') setting: Settings;

  constructor(public sharedDataService: SharedDataService) { }

  ngOnInit(): void {
  }

}
