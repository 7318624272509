import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth-service.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {LocalStorageServiceEncrypt} from 'angular-2-local-storage-encrypt';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  @ViewChild('registerForm') registerForm;
  errors = {
    name: null,
    email: null,
    phone: null,
    password: null,
    passwordConfirmation: null,
    message: null,
  };
  btnLoading: boolean;
  showSubmitError: boolean;

  constructor(private authService: AuthService,
              private ngxLoader: NgxUiLoaderService,
              private router: Router) {
  }

  submitRegister(registerForm: NgForm): void {
    if (registerForm.invalid) {
      this.showSubmitError = true;
      this.btnLoading = false;
      return;
    }
    if (registerForm.value.password !== registerForm.value.password_confirmation) {
      this.errors.password = 'Password confirmation not matched';
      this.btnLoading = false;
      return;
    }
    registerForm.form.setErrors({invalid: true});
    this.ngxLoader.start();
    this.authService.register(registerForm.value).subscribe(() => {
      this.registerForm.reset();
    }, error => this.handleResponseError(error.error.errors, registerForm));
  }

  private handleResponseError(errors, form): void {
    for (const error of errors) {
      this.errors[error.name] = error.message;
    }
    form.form.setErrors(null);
    this.showSubmitError = false;
    this.btnLoading = false;
    this.ngxLoader.stopAll();
  }

}
