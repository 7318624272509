import {FavouriteComponent} from './pages/favourite/favourite.component';
import {HomeResolver} from './pages/home/services/HomeResolver';
import {HomeComponent} from './pages/home/home.component';
import {ShopComponent} from './pages/shop/shop.component';
import {RouteData} from './shared/model/RouteData';
import {productPageResolver} from './pages/product/services/productPageResolver';
import {ProductComponent} from './pages/product/product.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FavouriteResolver} from './pages/favourite/services/FavouriteResolver';
import {LoginComponent} from './pages/auth/component/login/login.component';
import {AuthGuard} from './shared/middleware/auth.guard';
import {RegisterComponent} from './pages/auth/component/register/register.component';
import {ActivationComponent} from './pages/auth/component/activation/activation.component';
import {ActivationGuard} from './shared/middleware/activation.guard';
import {ForgetPasswordComponent} from './pages/auth/component/forget-password/forget-password.component';

const routes: Routes = [
  {path: 'product/:id', component: ProductComponent, data: RouteData.product, resolve: {response: productPageResolver}},
  {path: 'shop', component: ShopComponent, data: RouteData.shop, resolve: {response: productPageResolver}},
  {path: 'home', component: HomeComponent, data: RouteData.home, resolve: {response: HomeResolver}},
  {path: 'favourite', component: FavouriteComponent, data: RouteData.favourite, resolve: {response: FavouriteResolver}},
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'activation',
    component: ActivationComponent,
    canDeactivate: [ActivationGuard],
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    canActivate: [AuthGuard]
  },
  {path: '**', redirectTo: 'home'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
