<!--------------------------------------------------------------------------
----------------------------------Header------------------------------------
--------------------------------------------------------------------------->
<header class="offwhite-bg  py-lg-1 fixed-lg-top shadow-sm">
    <div class="container">
        <!----------------------------------Top bar------------------------------------>
        <ul class="nav top-bar">
            <span class="nav-link secondary pt-2">Ship To</span>
            <li class="nav-item pt-1">
                <select name="lang" class="form-control radius-50 select2-language">
                    <option value="1">Egypt</option>
                    <option value="2">UAE</option>
                </select>
            </li>
            <span class="vertical-separetor d-md-inline-block d-none"></span>
            <li class="nav-item d-md-inline-block d-none">
                <a class="nav-link secondary" href="#" title="Track your order">
                    <i class="fas fa-shipping-fast grey"></i>
                    Track your order
                </a>
            </li>
            <span class="vertical-separetor  d-md-inline-block d-none"></span>
            <li class="nav-item d-md-inline-block d-none">
                <a class="nav-link secondary" href="#" title="Free delivery">
                    <i class="fas fa-shipping-fast grey"></i>
                    Free delivery
                </a>
            </li>
            <li class="nav-item ml-auto">
                <a class="nav-link secondary" href="#" title="Website Language">
                    <span>العربيه</span>
                    <i class="fas fa-globe-europe secondary"></i>
                </a>
            </li>
        </ul>
        <hr class="my-0">
        <!----------------------------------Top bar------------------------------------>

        <!----------------------------------Middle bar------------------------------------>
        <nav class="navbar navbar-expand-lg middle-bar">
            <a class="navbar-brand w-lg-25" href="#" title="Site Logo">
                <img src="./assets/images/Group 1@2x.png" alt="" title="Site logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fas fa-bars secondary"></span>
            </button>

            <div class="collapse navbar-collapse row" id="navbarSupportedContent">
                <ul class="navbar-nav col-lg-12">

                    <form class="form-inline position-relative w-lg-75 w-100">
                        <i class="fas fa-search grey position-absolute search-icon"></i>
                        <input class="form-control px-4 radius-right-11 primary-border col-lg-10 col" type="search" placeholder="What are you looking for?" aria-label="Search">
                        <button class="btn primary-bg text-white my-2 my-sm-0 radius-left-11" type="submit">
                            <i class="fas fa-search text-white"></i>
                            Search
                        </button>
                    </form>

                    <li class="nav-item">
                        <a class="nav-link secondary" href="#" title="Shopping Cart">
                            <i class="fas fa-shopping-cart"></i>
                            <span class="d-inline-block d-lg-none">Shopping Cart</span>
                        </a>
                    </li>

                    <li class="nav-item" routerLinkActive="active" [routerLink]='["/favourite"]' >
                        <a class="nav-link secondary"  title="Favorites">
                            <i class="far fa-heart"></i>
                            <span class="d-inline-block d-lg-none">Favorites</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a routerLink="login" class="nav-link secondary" href="#" title="Favorites">
                            <i class="far fa-user"></i>
                            <span>Registering</span>
                        </a>
                    </li>

                    <li class="nav-item d-inline-block d-md-none">
                        <a class="nav-link secondary" href="#" title="Track your order">
                            <i class="fas fa-shipping-fast grey"></i>
                            Track your order
                        </a>
                    </li>

                    <li class="nav-item d-inline-block d-md-none">
                        <a class="nav-link secondary" href="#" title="Free delivery">
                            <i class="fas fa-shipping-fast grey"></i>
                            Free delivery
                        </a>
                    </li>


                </ul>
            </div>
        </nav>
        <hr class="my-0">
        <!----------------------------------Middle bar------------------------------------>

        <!----------------------------------Bottom bar------------------------------------>
        <nav class="navbar navbar-expand-lg bottom-bar">


            <div class="collapse navbar-collapse row" id="navbarSupportedContent">
                <ul class="navbar-nav col-lg-12">


                    <li class="nav-item order-lg-0 order-6">
                        <a class="nav-link text-white primary-bg btn radius-6 px-3 mr-2 mt-1" href="#" title="Categories" id="MegaMenuDrawer">
                            <i class="fas fa-bars"></i>
                            <i class="fas fa-times d-none"></i>
                            <span>CATEGORIES</span>
                        </a>
                    </li>

                    <li class="nav-item mx-lg-2">
                        <a routerLink="home" class="nav-link segoe-samibold px-0 secondary d-inline-block" href="index.html" title="Home">
                            <span>HOME</span>
                        </a>
                    </li>

                    <li class="nav-item  mx-lg-2">
                        <a class="nav-link segoe-samibold px-0 secondary d-inline-block"  [routerLink]="[ '/product' ]"  title="Shop">
                            <span>SHOP</span>
                        </a>
                    </li>

                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-samibold px-0 secondary d-inline-block" href="about-us.html" title="Shop">
                            <span>ABOUT US</span>
                        </a>
                    </li>


                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-samibold px-0 secondary d-inline-block" href="#" title="Shop">
                            <span>BLOG</span>
                        </a>
                    </li>

                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-samibold px-0 secondary d-inline-block" href="#" title="Shop">
                            <span>CONTACT</span>
                        </a>
                    </li>




                </ul>
            </div>
        </nav>
        <hr class="my-0 megaMunu-separator">
        <!----------------------------------Bottom bar------------------------------------>
        <!----------------------------------Bottom bar Mega menu------------------------------------>
        <nav class="navbar navbar-expand-lg middle-bar" id="megaMunu">

            <div class="collapse navbar-collapse row" id="navbarSupportedContent">
                <ul class="navbar-nav col-lg-12">

                    <li class="nav-item dropdown mega-dropdown">
                        <a class="nav-link dropdown-toggle grey" title="All Categories" data-toggle="dropdown" >
                            All Categories
                        </a>
                        <ul class="dropdown-menu mega-menu mega-dropdown-menu offwhite-bg">
                            <li class="row">
                                <ul class="col-4 list-unstyled">
                                    <li><a class="dropdown-item grey" href="#">
                                        block 1</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 2</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 3</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 4</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 5</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 6</a>
                                    </li>
                                </ul>
                                <ul class="col-4 list-unstyled">
                                    <li><a class="dropdown-item grey" href="#">
                                        block 7</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 8</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 9</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 10</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 11</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 12</a>
                                    </li>
                                </ul>
                                <ul class="col-4 list-unstyled">
                                    <li><a class="dropdown-item grey" href="#">
                                        block 13</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 14</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 15</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 16</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 17</a>
                                    </li>
                                    <li><a class="dropdown-item grey" href="#">
                                        block 18</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>


                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-semibold px-0 grey" href="#" title="Category">
                            <span>Category</span>
                        </a>
                    </li>

                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-semibold px-0 grey" href="#" title="Category">
                            <span>Category</span>
                        </a>
                    </li>

                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-semibold px-0 grey" href="#" title="Category">
                            <span>Category</span>
                        </a>
                    </li>

                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-semibold px-0 grey"  href="#" title="Category">
                            <span>Category</span>
                        </a>
                    </li>

                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-semibold px-0 grey" href="#" title="Category">
                            <span>Category</span>
                        </a>
                    </li>
                    <span class="vertical-separetor d-lg-inline-block d-none"></span>

                    <li class="nav-item mx-lg-2">
                        <a class="nav-link segoe-semibold px-0 red" href="#" title="Hot Deals">
                            <i class="fas fa-fire red"></i>
                            <span>Hot deals</span>
                        </a>
                    </li>



                </ul>
            </div>
            <hr class="my-0">
        </nav>
        <!----------------------------------Bottom bar Mega menu------------------------------------>


    </div>
</header>
<!--------------------------------------------------------------------------
----------------------------------Header------------------------------------
--------------------------------------------------------------------------->
