import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.css'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class EmailInputComponent implements OnInit {
  @Input() cols = 'col-lg-6 col-12';
  @Input() name: string;
  @Input() placeholder: string;
  @Input() error: string;
  @Input() showSubmitError: boolean;
  @Input() isRequired = true;
  @Input() minLength = 1;
  @Input() maxLength = 50;

  constructor() {
  }

  ngOnInit(): void {
  }

}
