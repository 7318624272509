import { ProductService } from './product.service';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ProductResponseModel } from "./../model/ProductResponseModel";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
    providedIn: 'root'
})
export class productPageResolver implements Resolve<ProductResponseModel>{
    constructor(private ProductService: ProductService, private ngxLoader: NgxUiLoaderService,) {
        // this.ngxLoader.start();

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductResponseModel> | Promise<ProductResponseModel> | ProductResponseModel {
        let id = route.paramMap.get('id');

        let products = this.ProductService.getProductById(id);
        // this.ngxLoader.stopAll();
        return products;
    }
}
