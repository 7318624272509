import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth-service.service';
import {NgForm} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {
  @ViewChild('activationForm') activationForm;
  errors = {
    code: null,
    message: null,
  };
  btnLoading: boolean;
  showSubmitError: boolean;

  constructor(private authService: AuthService,
              private ngxLoader: NgxUiLoaderService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.authService.user.subscribe(user => {
      if (!user || user.activation === 1){
        this.router.navigate(['']);
      }
    });
  }

  submitActivation(activationForm: NgForm): void {
    if (activationForm.invalid) {
      this.showSubmitError = true;
      this.btnLoading = false;
      return;
    }
    activationForm.form.setErrors({invalid: true});
    this.ngxLoader.start();
    this.authService.activation(activationForm.value).subscribe(() => {
      this.activationForm.reset();
    }, error => this.handleResponseError(error.error.errors, activationForm));
  }

  private handleResponseError(errors, form): void {
    for (const error of errors) {
      this.errors[error.name] = error.message;
    }
    form.form.setErrors(null);
    this.showSubmitError = false;
    this.btnLoading = false;
    this.ngxLoader.stopAll();
  }

}
