import { environment } from './../../../environments/environment';
import { Image } from './../../shared/model/ImageModel';
import { SharedDataService } from './../../shared/SharedData/shared-data.service';
import { element } from 'protractor';
import { ProductResponseModel, } from './model/ProductResponseModel';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from './services/product.service';
import { ProductCardComponent } from './../../shared/component/product-card/product-card.component';
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  constructor(private route: ActivatedRoute, private ngxLoader: NgxUiLoaderService, public sharedDataService: SharedDataService) {

  }

  pageObj: ProductResponseModel;


  // -----------------------------------ui development
  imageSrc = '';
  imagesSrcArray!: Image[];
  currentRate = 3
  quantity = 1
  url = environment.url
  maxQuantity
  //  ----------------------------------- ui development

  ngOnInit(): void {
    this.ngxLoader.start();

    this.getPageData();
    this.ngxLoader.stopAll();

    this.initImages();

  }


  getPageData() {
    this.route.data.subscribe(
      (resolverData) => {
        this.pageObj = resolverData.response;
      })
    // set the max amount to select
    this.maxQuantity = this.pageObj.product.stock
  }

  initImages() {
    this.imagesSrcArray = this.pageObj?.product.images
    this.imageSrc = this.imagesSrcArray[0].image
  }

  updateQuantitiy(cond) {
    if (cond == 'increase') {
      this.quantity += 1;
    }
    else{
      this.quantity -=1
    }
  }

  changeImage(src) {
    this.imageSrc = src;
  }
  checkActiveImage(src) {

    return this.imageSrc === src;
  }
}
