import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiLinks } from 'src/app/shared/model/ApiLinks';
import { environment } from 'src/environments/environment';
import { HomeResponseModel } from '../model/HomeResponseModel';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private url = environment.url;
  private header = environment.header;
  private api = ApiLinks
  constructor(private http: HttpClient) { }

  getHome() {
    // return this.http.get<FeaturedCoursesResponse>(this.url + api);
    return this.http.get<HomeResponseModel>(this.url + this.api.home );

  }

}
