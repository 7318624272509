<!-- <div class="radius-11 overflow-hidden position-relative">
    <img [src]=" url + ad.image" [alt]='ad["sub_title_"+sharedDataService.currentLang]'>
    <a href="#" Title="Link Title"
        class="btn bg-white primary radius-6 shadow-sm position-absolute shop-now-ad-position p-0">
        <i class="fas fa-home p-2"></i>
        <span class="btn-vertical-separetor"></span>
        <span class="p-2"> SHOP NOW</span>
    </a>
</div> -->


<div class="col-12 px-0">

    <div class="radius-11 overflow-hidden position-relative">
        <img [src]=" url + ad.image" [alt]='ad["sub_title_"+sharedDataService.currentLang]'>

        <a href="#" Title="Link Title"
            class="btn bg-white primary radius-6 shadow-sm position-absolute shop-now-slider-position p-0">
            <i class="fas fa-home p-2"></i>
            <span class="btn-vertical-separetor"></span>
            <span class="p-2"> SHOP NOW</span>
        </a>
    </div>

</div>