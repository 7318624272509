<div class="offwhite-bg py-1">
    <div class="container">

        <ul class="Tabs">
            <li  class="description_tab  pointer Tab" id="tab-title-description" [ngClass]="{'active': switcher =='Overview'}">
                <a class="secondary" (click)="changeSwithcer('Overview')">Overview</a>
            </li>
            <li data-show="#tab-reviews" class="description_tab Tab pointer" id="tab-title-reviews" [ngClass]="{'active': switcher =='Reviews'}">
                <a class="secondary" (click)="changeSwithcer('Reviews')">Reviews</a>
            </li>
            <li class="slider" role="presentation"></li>
        </ul>

    </div>
</div>


<div class="container py-4" id="">
    <div id="tab-description" *ngIf="switcher =='Overview'"  [innerHtml]='description'>
    </div>



    <div id="tab-reviews" *ngIf="switcher =='Reviews'" >
        Reviews
    </div>

</div>