<!--------------------------------------------------------------------------
---------------------------------- Daily Deals ------------------------------------
--------------------------------------------------------------------------->
<section class="py-3 sav-from-nav">
    <div class="container">
        <div>

            <main>
                <h6 class="secondary">SHOP</h6>
                <hr>
            </main>

            <div class="row">

                <div class="col-lg-3">

                    <div class="mb-1">
                        <div class="drawer mb-2 pointer">
                            <h4 class="segoe-bold font-weight-bold secondary float-left">Price ($)</h4>
                            <i class="float-right fas fa-chevron-down pt-2"></i>
                            <div class="clearfix"></div>
                        </div>

                        <form class="drawer row px-0 mb-4">

                            <div class="col-5 pr-0">
                                <input type="text" class="form-control price-height" placeholder="2">
                            </div>

                            <div class="col-1">TO</div>

                            <div class="col-5 pr-0">
                                <input type="text" class="form-control price-height" placeholder="2000">
                            </div>

                        </form>
                    </div>

                    <div class="mb-1">
                        <div class="drawer mb-2 pointer">
                            <h4 class="segoe-bold font-weight-bold secondary float-left">Feature</h4>
                            <i class="float-right fas fa-chevron-down pt-2"></i>
                            <div class="clearfix"></div>
                        </div>

                        <form class="drawer row pr-0 pl-lg-2 mb-4">


                            <div class="col-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label grey" for="defaultCheck1">
                                        new arrivals
                                    </label>
                                    <label class="float-right grey">
                                        (658)
                                    </label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2">
                                    <label class="form-check-label grey" for="defaultCheck2">
                                        new arrivals
                                    </label>
                                    <label class="float-right grey">
                                        (658)
                                    </label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck3">
                                    <label class="form-check-label grey" for="defaultCheck3">
                                        new arrivals
                                    </label>
                                    <label class="float-right grey">
                                        (658)
                                    </label>
                                </div>
                            </div>


                        </form>
                    </div>


                    <div class="mb-1">
                        <div class="drawer mb-1 pointer">
                            <h4 class="segoe-bold font-weight-bold secondary float-left">Categories</h4>
                            <i class="float-right fas fa-chevron-down pt-2"></i>
                            <div class="clearfix"></div>
                        </div>

                        <form class="drawer row pr-0 pl-lg-2 mb-4">

                            <div class="col-12 mb-1">

                                <div class="mb-2">
                                    <h5 class="form-check-label secondary d-inline-block seguo-samibold">
                                        new arrivals
                                    </h5>
                                    <label class="float-right grey">
                                        (2500)
                                    </label>
                                </div>


                                <div class="pl-lg-3">

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck4">
                                        <label class="form-check-label grey" for="defaultCheck4">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck5">
                                        <label class="form-check-label grey" for="defaultCheck5">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck6">
                                        <label class="form-check-label grey" for="defaultCheck6">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                </div>

                            </div>

                            <div class="col-12 mb-1">

                                <div class="mb-2">
                                    <h5 class="form-check-label secondary d-inline-block segoe-samibold">
                                        new arrivals
                                    </h5>
                                    <label class="float-right grey">
                                        (2500)
                                    </label>
                                </div>


                                <div class="pl-lg-3">

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck7">
                                        <label class="form-check-label grey" for="defaultCheck7">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck8">
                                        <label class="form-check-label grey" for="defaultCheck8">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck9">
                                        <label class="form-check-label grey" for="defaultCheck9">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                </div>

                            </div>

                            <div class="col-12 mb-1">

                                <div class="mb-2">
                                    <h5 class="form-check-label secondary d-inline-block segoe-samibold">
                                        new arrivals
                                    </h5>
                                    <label class="float-right grey">
                                        (2500)
                                    </label>
                                </div>


                                <div class="pl-lg-3">

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck10">
                                        <label class="form-check-label grey" for="defaultCheck10">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck11">
                                        <label class="form-check-label grey" for="defaultCheck11">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck12">
                                        <label class="form-check-label grey" for="defaultCheck12">
                                            new arrivals
                                        </label>
                                        <label class="float-right grey">
                                            (658)
                                        </label>
                                    </div>

                                </div>

                            </div>


                        </form>
                    </div>

                </div>

                <div class="col-lg-9">

                    <div class="row">

                        <div class="col-lg-5 d-flex mb-2 mb-lg-0">
                            <label class="secondary d-inline-block pt-2 w-25 mr-1 fs-9">SORT BY</label>
                            <select name="" class="form-control search-select radius-11 h-35">
                                <option value="">Price Low To High</option>
                            </select>
                        </div>

                        <div class="col-lg-5 d-flex">
                            <label class="secondary d-inline-block pt-2 w-25 mr-1 fs-9">DISPLAY</label>
                            <select name="" class="form-control search-select radius-11 h-35">
                                <option value="">8 Per Page</option>
                            </select>
                        </div>

                    </div>

                    <div class="row mt-3">


                        <div class="col-12">
                            <app-carousel></app-carousel>
                        
                        </div>
                               
                        <!---------------------------------Product----------------------------------->
                        <div class="col-lg-4 mb-3 pl-lg-0" *ngFor="let item of [1,2,3,4,5,6]">

                            <app-product-card></app-product-card>

                        </div>
                        <!---------------------------------Product----------------------------------->
                 <!-- <app-carousel [component]='prod' [loop]='[1,2,3,4,5,7]'></app-carousel> -->
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                        <ul class="list-unstyled list-inline">

                            <li class="list-inline-item px-2 offwhite-bg radius-6">
                                <a href="">
                                    <i class="fas fa-chevron-left"></i>
                                </a>
                            </li>

                            <li class="list-inline-item px-2">
                                <a href="" class="grey font-weight-bold">
                                    <span>1</span>
                                </a>
                            </li>

                            <li class="list-inline-item px-2">
                                <a href="" class="grey font-weight-bold">
                                    <span>2</span>
                                </a>
                            </li>

                            <li class="list-inline-item px-2">
                                <a href="" class="grey font-weight-bold">
                                    <span>3</span>
                                </a>
                            </li>

                            <li class="list-inline-item px-2">
                                <a href="" class="grey font-weight-bold">
                                    <span>4</span>
                                </a>
                            </li>

                            <li class="list-inline-item px-2 offwhite-bg radius-6">
                                <a href="">
                                    <i class="fas fa-chevron-right"></i>
                                </a>
                            </li>

                        </ul>
                    </div>

                </div>

            </div>

        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------Daily Deals------------------------------------
--------------------------------------------------------------------------->