    <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let brand of brands; let i=index">
        <ng-template carouselSlide>

            <div class="position-relative">
                <img [src]='url+ brand.image' [alt]="brand['name_'+sharedDataService.currentLang]"
                    class="img-fluid object-fit-scaledown my-auto">
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>



<!--------------------------------------------------------------------------
----------------------------------OUR PARTNERS------------------------------------
--------------------------------------------------------------------------->