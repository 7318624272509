import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-overview-description',
  templateUrl: './overview-description.component.html',
  styleUrls: ['./overview-description.component.css']
})

export class OverviewDescriptionComponent implements OnInit {

  @Input() overView:string;
  @Input() description:string;
  
  switcher = 'Overview'

  constructor() { }

  ngOnInit(): void {
  }

  changeSwithcer(input: string) {

    this.switcher = input

  }
}
