import {CartService} from './../../shared/services/cart/cart.service';
import {Product} from './../../shared/model/ProductModel';
import {FavouriteService} from './../../shared/services/favourite/favourite.service';
import {GeneralService} from './../../shared/services/general/general.service';
import {HomeResponseModel} from './model/HomeResponseModel';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {SharedDataService} from 'src/app/shared/SharedData/shared-data.service';
import collect from 'collect.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  pageObj: HomeResponseModel;

  constructor(private route: ActivatedRoute,
              private ngxLoader: NgxUiLoaderService,
              public sharedDataService: SharedDataService,
              private favouriteService: FavouriteService,
              private cartService: CartService) {
  }

  ngOnInit(): void {
    this.getPageData();

    // localStorage.setItem('user', JSON.stringify({
    //   "id": 852,
    //   "name": "yasminaa",
    //   "email": "gg.sami@intcore.net",
    //   "phone": "011993134211",
    //   "gender": null,
    //   "birth_date": null,
    //   "activation": 5239,
    //   "type": 1,
    //   "image": "img/default-profile-picture.png",
    //   "cover": "img/default-cover-profile.jpg",
    //   "reset_password_code": null,
    //   "api_token": "29bb8e1d5f35bc198ef0bf0ab35d14fd",
    //   "country_id": null,
    //   "created_at": "2021-04-06 11:46:41",
    //   "updated_at": "2021-04-06 14:54:08",
    //   "mobile_token": null,
    //   "os": null,
    //   "facebook_token": null,
    //   "google_token": null,
    //   "temp_phone_code": null,
    //   "address_id": null,
    //   "social_id": null,
    //   "social_type": null,
    //   "city_id": null,
    //   "is_influencer": 0,
    //   "is_influencer_approved": 0,
    //   "cart_count": 4
    // }))
    this.favouriteService.getFav();
    this.favouriteService.favProduct.subscribe(pr => console.log(`pr`, pr));
    this.cartService.cartProductResponse.subscribe(pr => console.log(`cart`, pr));


    // let x = [
    //   {id:1 ,quntity:5},
    //   {id:2 ,quntity:5},
    //   {id:3 ,quntity:5},
    //   {id:4 ,quntity:5},
    // ]

    // console.log(`x`, collect( x).search((item,ket)=> item.id ==5));
    // let ids = collect(x).pluck('id').toArray();
    // console.log(`ids`, ids);

  }

  ngAfterViewInit() {
    this.ngxLoader.stopAll();
  }

  getPageData() {
    this.route.data.subscribe(
      (resolverData) => {
        this.pageObj = resolverData.response;
      });
  }
}
