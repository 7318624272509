import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HomeResponseModel } from 'src/app/pages/home/model/HomeResponseModel';
import { ApiLinks } from 'src/app/shared/model/ApiLinks';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  private url = environment.url;
  private header = environment.header;
  private api = ApiLinks
  constructor(private http: HttpClient) { }


  subscribeToNewsteller(subscriberEmail) {
    return this.http.post(this.url + this.api.subscribeToNewsteller, { email: subscriberEmail });

  }
}
