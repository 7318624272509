import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {ApiLinks} from '../../../shared/model/ApiLinks';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthResponseModel} from '../models/AuthResponseModel';
import {tap} from 'rxjs/operators';
import {LocalStorageServiceEncrypt} from 'angular-2-local-storage-encrypt';
import {User} from '../models/user.model';
import {FavouriteService} from '../../../shared/services/favourite/favourite.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CartService} from '../../../shared/services/cart/cart.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private url = environment.url;
  private api = ApiLinks;

  constructor(private http: HttpClient,
              private router: Router,
              private favService: FavouriteService,
              private localStorage: LocalStorageServiceEncrypt,
              private ngxLoader: NgxUiLoaderService,
              private cartService: CartService) {
  }


  register(registerData): Observable<AuthResponseModel> {
    const data = this.getFullData(registerData);
    return this.http.post<AuthResponseModel>(this.url + this.api.signup, data)
      .pipe(tap(responseData => this.handleAuthentication(responseData)));
  }

  login(loginData): Observable<AuthResponseModel> {
    const data = this.getFullData(loginData);
    return this.http.post<AuthResponseModel>(this.url + this.api.signin, data)
      .pipe(tap(responseData => this.handleAuthentication(responseData)));
  }

  activation(data): Observable<any> {
    return this.http.post<any>(this.url + this.api.activation, data)
      .pipe(tap(responseData => this.handleAuthentication(responseData)));
  }

  logout(): void {
    this.localStorage.remove('user');
    this.router.navigate(['/']);
  }

  sendResetPasswordCode(data): Observable<any> {
    return this.http.post<any>(this.url + this.api.sendPasswordCode, data);
  }

  resetPasswordCode(data): Observable<any> {
    return this.http.patch<AuthResponseModel>(this.url + this.api.resetPassword, {}, {params: data});
  }

  autoLogin(): void {
    const userData = this.localStorage.get('user');
    if (!!userData) {
      this.handleAuthentication(userData);
    }
  }

  private handleAuthentication(responseData): void {
    const user = new User(responseData.user.id, responseData.user.name, responseData.user.email, responseData.user.phone,
      responseData.user.activation, responseData.user.image, responseData.user.type, responseData.user.api_token,
      responseData.user.cart_count, responseData.user.cart_count);
    this.user.next(user);
    this.localStorage.set('user', {user});
    this.favService.cleanLocalFav();
    this.cartService.cleanLocalCart();
    if (user.activation === 1) {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['activation']);
    }
    this.ngxLoader.stopAll();
  }

  private getFullData(loginData): object {
    const data = loginData;
    this.favService.fav.subscribe(favourites => {
      data.favorites = JSON.stringify(favourites);
    });

    this.cartService.cart.subscribe(cart => {
      data.carts = JSON.stringify(cart);
    });
    return data;
  }


}
