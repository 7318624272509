import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cleanHtml'
})

export class CleanHtmlPipe implements PipeTransform {

    transform(value: string): string {
        return value.replace(/<[^>]*>/g, '');
    }
}