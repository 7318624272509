import { Product } from './../../model/ProductModel';
import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.css']
})
export class ProductsCarouselComponent implements OnInit {

  @Input('products') products: Product[];


  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    // responsiveClass: true,
    // nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      }
    }
  };
  constructor() { }

  ngOnInit(): void {
  }

}
