import { FavouriteService } from './../../../shared/services/favourite/favourite.service';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
    providedIn: 'root'
})
export class FavouriteResolver implements Resolve<any>{
    constructor(private favouriteService: FavouriteService, private ngxLoader: NgxUiLoaderService,) {
        // this.ngxLoader.start();
        this.ngxLoader.start();


    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this.favouriteService.getFav();
        // this.ngxLoader.stopAll();
    }
}
