// import { AlertServicesService } from 'src/app/services/alertServices/alert-services.service';
import {Injectable} from '@angular/core';
import {tap, map} from 'rxjs/operators';
import swal from 'sweetalert2';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {AuthService} from '../../../pages/auth/services/auth-service.service';
import {User} from '../../../pages/auth/models/user.model';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class HttpRequstesInterceptor implements HttpInterceptor {
  // public startBackgroundLoader = false // to load the main loader
  token: string;

  constructor(private router: Router,
              private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // add token to every header
    const user = this.authService.user.subscribe(user => {
      if (user) {
        this.token = 'Bearer ' + user.token;
      }
    });

    if (this.token) {
      request = request.clone({
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: this.token,
        })
      });
    } else {
      request = request.clone({
        headers: new HttpHeaders({
          Accept: 'application/json',
          // 'country_id': '2',
        })
      });
    }

    return next.handle(request).pipe(
      tap({
        next: val => {
          // on next 11, etc..


        },
        //handel general errors here
        error: error => {

        },
        complete: () => {
        }


      })
    );

  }
}
