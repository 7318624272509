import { SharedDataService } from './../../SharedData/shared-data.service';
import { environment } from './../../../../environments/environment';
import { Ad } from './../../../pages/home/model/HomeResponseModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.css']
})
export class AdComponent implements OnInit {

  @Input('ad') ad: Ad;
  url = environment.url;
  constructor( public sharedDataService:SharedDataService) { }

  ngOnInit(): void {
  }

}
