<app-center-card>
  <form (ngSubmit)="submitActivation(activationForm)" #activationForm="ngForm" class="row px-3">

    <app-number-input class="col-lg-12 col-12" name="code" placeholder="Activation Code"
                      [minLength]="4" [maxLength]="4"
                      [error]="errors.message"></app-number-input>

    <app-submit-btn class="col-12 text-lg-right text-center" type="submit" [disabled]="activationForm.invalid"
                    [loading]="btnLoading" (isLoading)="btnLoading = $event" bgColor="primary-bg"
                    color="text-white"></app-submit-btn>
  </form>
</app-center-card>
