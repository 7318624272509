import {Component, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../services/auth-service.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {LocalStorageServiceEncrypt} from 'angular-2-local-storage-encrypt';
import {AuthResponseModel} from '../../models/AuthResponseModel';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  @ViewChild('loginForm') loginForm;
  errors = {
    name: null,
    password: null,
    message: null,
  };
  btnLoading: boolean;
  showSubmitError: boolean;

  constructor(private authService: AuthService,
              private ngxLoader: NgxUiLoaderService) {
  }

  submitLogin(loginForm: NgForm): void {
    if (loginForm.invalid) {
      this.showSubmitError = true;
      this.btnLoading = false;
      return;
    }
    loginForm.form.setErrors({invalid: true});
    this.ngxLoader.start();
    this.authService.login(loginForm.value).subscribe((user: AuthResponseModel) => {
      this.loginForm.reset();
    }, error => this.handleResponseError(error.error.errors, loginForm));
  }

  private handleResponseError(errors, form): void {
    for (const error of errors) {
      this.errors[error.name] = error.message;
    }
    form.form.setErrors(null);
    this.showSubmitError = false;
    this.btnLoading = false;
    this.ngxLoader.stopAll();
  }

}
