<app-center-card>

  <form (ngSubmit)="submitSendCodeForm(sendCodeForm)" #sendCodeForm="ngForm" class="row px-3"
        *ngIf="formType == forgetPasswordEnums.forgetPassword">
    <app-number-input class="col-12" name="phone" placeholder="Phone"
                      [minLength]="10" [maxLength]="10"
                      [error]="errors.phone"></app-number-input>

    <app-submit-btn class="col-12 text-lg-right text-center" type="submit" [disabled]="sendCodeForm.invalid"
                    [loading]="btnLoading" (isLoading)="btnLoading = $event" bgColor="primary-bg"
                    color="text-white"></app-submit-btn>
  </form>


  <form (ngSubmit)="submitResetForm(ResetForm)" #ResetForm="ngForm" class="row px-3"
        *ngIf="formType == forgetPasswordEnums.ResetPassword">
    <app-number-input class="col-12" name="reset_password_code" placeholder="Code"
                      [minLength]="4" [maxLength]="4"
                      [error]="errors.code"></app-number-input>

    <app-password-input class="col-lg-6 col-12" name="password"
                        placeholder="Password" [error]="errors.password"
                        [showSubmitError]="showSubmitError"></app-password-input>

    <app-password-input class="col-lg-6 col-12" name="password_confirmation"
                        placeholder="Password Confirmation" [error]="errors.passwordConfirmation"></app-password-input>

    <app-submit-btn class="col-12 text-lg-right text-center" type="submit" [disabled]="ResetForm.invalid"
                    [loading]="btnLoading" (isLoading)="btnLoading = $event" bgColor="primary-bg"
                    color="text-white"></app-submit-btn>
  </form>

  <div class="col-12">
    <h6>
      <span>Already have account</span>&nbsp;
      <a routerLink="/login">Login Now</a>
    </h6>
  </div>
</app-center-card>
