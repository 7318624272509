<!--------------------------------------------------------------------------
---------------------------------- Top Slider ------------------------------------
--------------------------------------------------------------------------->
<section id="slider">
    <div class="container">
        <div class="col-12 px-0">
            <app-image-slider [slider]='pageObj?.sliders'></app-image-slider>
        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------Top Slider------------------------------------
--------------------------------------------------------------------------->


<!--------------------------------------------------------------------------
---------------------------------- Category ------------------------------------
--------------------------------------------------------------------------->
<section class="py-3">
    <div class="container">
        <div class="row px-2">

            <div class="col-lg-4 col-6 my-2 px-2" *ngFor="let category of pageObj.top_categories let i=index">
                <app-category [category]='category'></app-category>
            </div>



        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------Category------------------------------------
--------------------------------------------------------------------------->



<!--------------------------------------------------------------------------
---------------------------------- New Arrival ------------------------------------
--------------------------------------------------------------------------->
<section class="py-4 footer-offwhite-bg" *ngIf="pageObj?.new_arrival.length>0">
    <div class="container">
        <div class="row px-3">

            <div class="mb-2 pb-2">
                <h4 class="segoe-bold mb-0">NEW ARRIVALS</h4>
                <span class="title-separator"></span>
            </div>

            <div class="col-12 px-0">
                <app-products-carousel [products]='pageObj?.new_arrival'></app-products-carousel>
            </div>

        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------New Arrival------------------------------------
--------------------------------------------------------------------------->


<!--------------------------------------------------------------------------
---------------------------------- banner ------------------------------------
--------------------------------------------------------------------------->
<section class="banner py-3">
    <div class="container px-3">
        <app-ad [ad]='pageObj?.ad'></app-ad>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------banner------------------------------------
--------------------------------------------------------------------------->

<!--------------------------------------------------------------------------
---------------------------------- Free Delivery ------------------------------------
--------------------------------------------------------------------------->
<section class="free-delivery py-5 my-3 primary-bg">
    <div class="container">
        <app-setting [setting]='pageObj?.settings'></app-setting>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------Free Delivery------------------------------------
--------------------------------------------------------------------------->

<!--------------------------------------------------------------------------
---------------------------------- Daily Deals ------------------------------------
--------------------------------------------------------------------------->
<section class="py-3">
    <div class="container">
        <div class="row px-3">

            <div class="mb-2 pb-2">
                <h4 class="segoe-bold mb-0">Daily Deals</h4>
                <span class="title-separator"></span>
            </div>

            <div class="col-12 px-0">
                <app-products-carousel [products]='pageObj?.hot_deals'></app-products-carousel>

            </div>

        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------Daily Deals------------------------------------
--------------------------------------------------------------------------->

<!--------------------------------------------------------------------------
---------------------------------- BEST SELLER ------------------------------------
--------------------------------------------------------------------------->
<section class="py-4 footer-offwhite-bg">
    <div class="container">
        <div class="row px-3">

            <div class="mb-2 pb-2">
                <h4 class="segoe-bold mb-0">BEST SELLER</h4>
                <span class="title-separator"></span>
            </div>

            <div class="col-12 px-0">
                <app-products-carousel [products]='pageObj?.best_seller'></app-products-carousel>

            </div>

        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------BEST SELLER------------------------------------
--------------------------------------------------------------------------->


<!--------------------------------------------------------------------------
---------------------------------- Blog ------------------------------------
--------------------------------------------------------------------------->
<section class="py-3">
    <div class="container">
        <div class="row px-0">

            <div class="col-12 mb-2 pb-2">
                <h4 class="segoe-bold mb-0">Blog</h4>
                <span class="title-separator"></span>
            </div>

            <div class="col-lg-4 col-md-6 my-3" *ngFor="let blog of pageObj?.blog">
                <app-blog-card [blog]='blog'> </app-blog-card>
            </div>



        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------Blog------------------------------------
--------------------------------------------------------------------------->

<!--------------------------------------------------------------------------
---------------------------------- OUR PARTNERS ------------------------------------
--------------------------------------------------------------------------->
<section class="py-3">
    <div class="container">
        <div class="row px-3">

            <div class="col-12 mb-2 pb-2 px-0">
                <h4 class="segoe-bold mb-0">OUR PARTNERS</h4>
                <span class="title-separator"></span>
            </div>

            <div class="col-12 px-0">
                <app-partner-carousel [brands]='pageObj?.brands'></app-partner-carousel>
            </div>


        </div>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------OUR PARTNERS------------------------------------
--------------------------------------------------------------------------->

<!--------------------------------------------------------------------------
---------------------------------- newsletter ------------------------------------
--------------------------------------------------------------------------->
<section class="newsletter py-5 mt-3 primary-bg">
    <div class="container">
        <app-newslettter></app-newslettter>
    </div>
</section>
<!--------------------------------------------------------------------------
----------------------------------newsletter------------------------------------
--------------------------------------------------------------------------->