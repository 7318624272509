<app-center-card>
  <form (ngSubmit)="submitLogin(loginForm)" #loginForm="ngForm" class="row px-3">
    <app-email-input class="col-lg-6 col-12" [minLength]="1" name="name"
                     placeholder="Name" [error]="errors.message"
                     [showSubmitError]="showSubmitError"></app-email-input>

    <app-password-input class="col-lg-6 col-12" name="password"
                        placeholder="Password" [error]="errors.password"
                        [showSubmitError]="showSubmitError"></app-password-input>

    <app-submit-btn class="col-12 text-lg-right text-center" type="submit" [disabled]="loginForm.invalid"
                    [loading]="btnLoading" (isLoading)="btnLoading = $event" bgColor="primary-bg"
                    color="text-white"></app-submit-btn>
    <div class="col-12">
      <h6>
        <span>Dont have account</span>&nbsp;
        <a routerLink="/register">Register Now</a>
      </h6>
      <h6>
        <span>Forget Password</span>&nbsp;
        <a routerLink="/forget-password">Reset Now</a>
      </h6>
    </div>
  </form>
</app-center-card>
