import { Cart } from './../../model/CartModel';
import { Product } from './../../model/ProductModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import collect from 'collect.js';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiLinks } from '../../model/ApiLinks';
import { CartResponse } from '../../model/CartModel';
import { AlertService } from '../alert/alert.service';
import { AuthService } from '../auth/auth.service';
import { GeneralService } from '../general/general.service';
import { parse } from 'path';
import { ParsedEvent } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})

export class CartService {


  user
  api = ApiLinks
  url = environment.url
  cart = new BehaviorSubject<{ id: number, quantity: number }[]>([]); // to get list of cart prodcut id
  cartProductResponse = new BehaviorSubject<CartResponse>(null); // this var is to get the list of cart product
  generalProduct
  private cartProcutIds: { id: number, quantity: number }[]; // subscribed variable to local cart ids  to work with it in this services internal

  constructor(private authService: AuthService, private http: HttpClient, private alertService: AlertService, private generalService: GeneralService) {
    this.authService.user.subscribe(user => { this.user = user })
    this.initData();
    this.generalService.products.subscribe(product => this.generalProduct = product)
  }


  //------------------------------save cart data ----------------------------------------------

  addTocart(product: Product, quantity: number) {
    if (this.user) {
      this.savecartServer(product, quantity);
      this.savecartlocal(product, quantity);
    }
    else {
      this.savecartlocal(product, quantity);
    }
  }

  savecartlocal(product: Product, quantity) {
    let cartProducts
    this.cart.subscribe(products => {
      // add the new product to the BehaviorSubject then save it in the  local storage
      cartProducts = products
    });
    if (cartProducts) { // check if there is already array of ids
      if (collect(cartProducts).search((item, key) => item.id == product.id)) // check if it not already exists to remove
      {
        let index = collect(cartProducts).search((item, key) => item.id == product.id)
        if (quantity == 0) // if the quatity is 0 then remove it from local storage
        {
          cartProducts.splice(index, 1) // delete it from the db
        }
        else { // else only update the quntity
          cartProducts[index].quantity = quantity
        }
      }
      else {
        cartProducts.push({ id: product.id, quntity: quantity })
      }
    }
    else { // if there is no data in storage then we need to start an array
      if (quantity > 0) // add only if the quantity is greater than 0
        cartProducts = [{ id: product.id, quntity: quantity }];
    }


    this.cart.next(cartProducts); // then add it to behaviourSubject to access it
    localStorage.setItem('products_cart', JSON.stringify(cartProducts))
  }

  savecartServer(product: Product, quantity) {
    this.http.post(this.url + this.api.cart, { product_id: product.id, quantity: quantity }).subscribe(
      (result: any) => {
        // this.alertService.successToast('product has been added successfully')
        // this.getcart();
        this.cartProductResponse.next(result);

        // this.alertService.successToast(this.translateService.instant('cartorite.product has been added successfully'))

      },
      (error: any) => {
        this.alertService.serverError(error);
      }

    )
  }

  //------------------------------end save cart data ----------------------------------------------

  //------------------------------get cart data ----------------------------------------------
  getcart() {
    if (this.user) {
      return this.getcartFromServer();
    }
    else {
      return this.getcartFromLocal();

    }
  }

  getcartFromServer() {
    this.http.get(this.url + this.api.getcart).subscribe(
      (result: CartResponse) => {
        this.cartProductResponse.next(result);
      },
      (error: any) => {
        this.alertService.serverError(error);
      }
    )
  }

  getcartFromLocal() {
    this.cart.subscribe(cartObject => { // get local id of cart product
      if (cartObject) {
        let ids = collect(cartObject).pluck('id').toArray(); // to get the array if ids instade of list of object {id,qunattity}
        this.generalService.getListOfProduct(ids) // get array list of product
        this.createCartResponseFromLocal();
      }
    }); // list of products ids
  }

  createCartResponseFromLocal() {
    let tempCart: Cart[]=[]
    let totalProduct = 0
    let total_price = 0

    this.generalService.products.subscribe(products => {
      products.forEach(element => {
        let index = collect(this.cart.value).search((item, key) => item.id == element.id);
        // add cart
        tempCart.push({ id: this.cart.value[index].id, quantity: this.cart.value[index].quantity, product_id: element.id, product: element })

        totalProduct += 1
        total_price += parseInt(element.price.price);
      });

      let tempResponse = {
        carts: tempCart,
        total_items: totalProduct,
        total_price: total_price,
      }

      this.cartProductResponse.next(tempResponse) // get subscribed data form services
    });

  }
  //------------------------------end get cart data ----------------------------------------------


  //---------------------------------- is cart or not
  inCart(product: Product): boolean {
    if (this.user) {
      let iscart = false;

      collect(this.cartProductResponse.value.carts).map(cart => {
        if (cart.product_id == product.id) {
          iscart = true;
        }
      })

      return iscart;

    }
    else {
      return collect(this.cartProcutIds).search((item, key) => item.id == product.id, true);
    }
  }

  initData() { // set intial value of user

    let localProduct = JSON.parse(localStorage.getItem('products_cart'));
    if (localProduct) {
      this.cart.next(localProduct);
    }
    else {
      this.cart.next([]);
    }
    this.cart.subscribe(ids => this.cartProcutIds = ids);
  }

  cleanLocalCart(): void {
    this.cart.next([]);
    JSON.stringify(localStorage.setItem('products_cart', JSON.stringify([])));
  }



}
