import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CarouselComponent } from './shared/component/carousel/carousel.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgxUiLoaderModule, NgxUiLoaderHttpModule, } from 'ngx-ui-loader';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductComponent } from './pages/product/product.component';
import { FooterComponent } from './shared/component/footer/footer.component';
import { NavComponent } from './shared/component/nav/nav.component';
import { ProductCardComponent } from './shared/component/product-card/product-card.component';
import { HttpClientModule, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpRequstesInterceptor } from './shared/services/http/http-requstes.interceptor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ProductsCarouselComponent } from './shared/component/products-carousel/products-carousel.component';
import { HomeComponent } from './pages/home/home.component';
import { ShopComponent } from './pages/shop/shop.component';
import { SubscribeToNewsComponent } from './shared/component/subscribe-to-news/subscribe-to-news.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverviewDescriptionComponent } from './pages/product/components/overview-description/overview-description.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OwlModule } from 'ngx-owl-carousel';
import { ImageSliderComponent } from './shared/component/image-slider/image-slider.component';
import { CategoryComponent } from './pages/home/component/category/category.component';
import { AdComponent } from './shared/component/ad/ad.component';
import { SettingComponent } from './pages/home/component/setting/setting.component';
import { BlogCardComponent } from './shared/component/blog-card/blog-card.component';
// pipes
import { TruncatePipe } from './shared/services/pipe/truncate.pipe';
import { CleanHtmlPipe } from './shared/services/pipe/cleanHtml';

import { PartnerCarouselComponent } from './shared/component/partner-carousel/partner-carousel.component';
import { NewslettterComponent } from './shared/component/newslettter/newslettter.component';
import { FavouriteComponent } from './pages/favourite/favourite.component';
import { LoginComponent } from './pages/auth/component/login/login.component';
import { TextInputComponent } from './shared/component/inputs/text-input/text.component';
import { CenterCardComponent } from './shared/component/cards/center-card/center-card.component';
import { PasswordInputComponent } from './shared/component/inputs/password-input/password-input.component';
import { EmailInputComponent } from './shared/component/inputs/email-input/email-input.component';
import { SubmitBtnComponent } from './shared/component/inputs/submit-btn/submit-btn.component';
import {LocalStorageModule} from 'angular-2-local-storage-encrypt';
import { RegisterComponent } from './pages/auth/component/register/register.component';
import { NumberInputComponent } from './shared/component/inputs/number-input/number-input.component';
import { NumbersOnlyDirective } from './shared/services/directive/numbers-only.directive';
import { ActivationComponent } from './pages/auth/component/activation/activation.component';
import { ForgetPasswordComponent } from './pages/auth/component/forget-password/forget-password.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    ProductComponent,
    ProductCardComponent,
    CarouselComponent,
    ProductsCarouselComponent,
    HomeComponent,
    ShopComponent,
    SubscribeToNewsComponent,
    OverviewDescriptionComponent,
    ImageSliderComponent,
    CategoryComponent,
    AdComponent,
    SettingComponent,
    BlogCardComponent,
    //pipe integrate
    TruncatePipe,
    CleanHtmlPipe,

    PartnerCarouselComponent,
    NewslettterComponent,
    FavouriteComponent,
    LoginComponent,
    TextInputComponent,
    CenterCardComponent,
    PasswordInputComponent,
    EmailInputComponent,
    SubmitBtnComponent,
    RegisterComponent,
    NumberInputComponent,
    NumbersOnlyDirective,
    ActivationComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    NoopAnimationsModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgbModule,
    LocalStorageModule.forRoot({
      prefix: 'intcore',
      storageType: 'localStorage',
      encryptionActive: true,
      encryptionOptions: {
        encryptionKey: 'thisIsATooLongKey',
        encryptionIv: 'iVHere',
        encryptionSalt: '6c6120106ed4bd055d3f941a3c87111dca4b47681748b4ecab90ccc392f8beaaf5976613961f26eef601db71af83e0efeea64c6e0ec855080791c7dc85678c63'
      }
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequstesInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
