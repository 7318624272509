import { SharedDataService } from './../../SharedData/shared-data.service';
import { environment } from './../../../../environments/environment';
import { Brand } from './../../../pages/home/model/HomeResponseModel';
import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Product } from '../../model/ProductModel';

@Component({
  selector: 'app-partner-carousel',
  templateUrl: './partner-carousel.component.html',
  styleUrls: ['./partner-carousel.component.css']
})
export class PartnerCarouselComponent implements OnInit {

  @Input('brands') brands: Brand[];

  url =environment.url
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    // responsiveClass: true,
    // nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      }
    }
  };
  constructor( public sharedDataService:SharedDataService) { }

  ngOnInit(): void {
  }

}
