<footer class="footer py-5 footer-offwhite-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 .col-lg-6">
                <img src="./assets//images//Group 1@2x.png" alt="" class="img-fluid" title="Site logo">
                <hr>
                <p class="grey text-justify">Start — Your business journey E-Commerce is an online marketplace personalized to your needs. Take your store to the web and have an easy-to-use online store with everything your customers need.</p>
            </div>

            <div class="col-lg-4 .col-lg-6">
                <h5 class="secondary segoe-samibold">IMPORTANT LINKS</h5>
                <hr>
                <ul class="list-unstyled">
                    <li class="my-2">
                        <a class="secondary " href="" title="Privacy Policy">Privacy Policy</a>
                    </li>
                    <li class="my-2">
                        <a class="secondary " href="" title="Privacy Policy">Privacy Policy</a>
                    </li>
                    <li class="my-2">
                        <a class="secondary " href="" title="Privacy Policy">Privacy Policy</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-4 .col-lg-6">
                <h5 class="secondary segoe-samibold">ADDRESS</h5>
                <hr>
                <ul class="list-unstyled">
                    <li class="my-2">
                        <a class="secondary " href="" title="Privacy Policy">347 Nile Cornish, Maadi, Cairo, Egypt</a>
                    </li>
                    <li class="my-2">
                        <a class="secondary " href="" title="Privacy Policy">info@intcore.com</a>
                    </li>
                    <li class="my-2">
                        <a class="secondary " href="" title="Privacy Policy">+21061000093</a>
                    </li>
                </ul>
            </div>

        </div>
        <hr>
        <div class="row text-center text-lg-left">
            <div class="col-lg-6">
                <p class="secondary font-weight-bold">© 2020 All Rights Reserved. Design By INTCore</p>
            </div>
            <div class="col-lg-6 text-lg-right text-center">
                <div class="secondary">
                    <h5 class="d-inline-block">Follow us on :</h5>
                    <a href="" class="primary vertical-align-middle mx-1" title="Facebook link">
                        <i class="fab fa-facebook primary fa-2x"></i>
                    </a>

                    <a href="" class="primary vertical-align-middle mx-1" title="instgram link">
                        <i class="fab fa-instagram primary fa-2x"></i>
                    </a>

                    <a href="" class="primary vertical-align-middle mx-1" title="twitter link">
                        <i class="fab fa-twitter primary fa-2x"></i>
                    </a>

                    <a href="" class="primary vertical-align-middle mx-1" title="Youtube link">
                        <i class="fab fa-youtube primary fa-2x"></i>
                    </a>


                </div>
            </div>

        </div>
        <hr>
        <div class="d-flex justify-content-center">
            <ul class="list-inline list-unstyled mb-0">
                <li class="list-inline-item">
                    <a href="" class="secondary segoe-samibold" title="shop">shop</a>
                </li>
                <li class="list-inline-item">
                    <a href="" class="secondary segoe-samibold" title="Home">Home</a>
                </li>
                <li class="list-inline-item">
                    <a href="" class="secondary segoe-samibold" title="about us">about us</a>
                </li>
                <li class="list-inline-item">
                    <a href="" class="secondary segoe-samibold" title="blog">blog</a>
                </li>
                <li class="list-inline-item">
                    <a href="" class="secondary segoe-samibold" title="contact">contact</a>
                </li>
            </ul>
        </div>
    </div>
</footer>