import { SharedDataService } from './../../SharedData/shared-data.service';
import { environment } from './../../../../environments/environment';
import { Blog } from './../../../pages/home/model/HomeResponseModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.css']
})
export class BlogCardComponent implements OnInit {

  @Input('blog') blog: Blog;
  url = environment.url
  constructor(public sharedDataService :SharedDataService) { }

  ngOnInit(): void {
  }

}
