<section class="sav-from-nav">

    <div class="container">

        <img src="./../../../assets/images/favourite.png" class="img-fluid" alt="">
        <table class="table table-striped">
            <thead>
                <tr class="text-capitalize">
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">name</th>
                    <th scope="col">describtion</th>
                    <th scope="col">action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let product of pageObject; let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td class="vertical-align-middle"> <img [src]="url +product.default_image" width="50" height="50"
                            class="rounded-circle" alt="{{product['name_' + sharedDataService.currentLang]}}"></td>
                    <td class="vertical-align-middle">{{product['name_' + sharedDataService.currentLang]}}</td>
                    <td class="vertical-align-middle">{{product['description_' +
                        sharedDataService.currentLang]|cleanHtml | truncate:[50,'...']}}</td>
                    <td class="vertical-align-middle"><i class="fas fa-trash primary red-hover pointer fs-1-3 mx-2"
                            (click)="remove(product)"></i></td>
                </tr>

            </tbody>
        </table>
        <div class="w-100 text-center py-3 text-capitalize" *ngIf="pageObject.length==0">there is no Favourite product</div>
        
    </div>
</section>