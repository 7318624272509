import { environment } from './../../../environments/environment';
import { SharedDataService } from './../../shared/SharedData/shared-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from './../../shared/services/alert/alert.service';
import { Product } from './../../shared/model/ProductModel';
import { FavouriteService } from './../../shared/services/favourite/favourite.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {

  pageObject: Product[]
  url = environment.url;
  constructor(private favouriteService: FavouriteService, private alertService: AlertService, private ngxUiLoader: NgxUiLoaderService, public sharedDataService: SharedDataService) { }

  ngOnInit(): void {
    this.favouriteService.favProduct.subscribe(prodcuts => this.pageObject = prodcuts)
    this.ngxUiLoader.stopAll();
  }


  remove(product) {
    this.favouriteService.addToFav(product);
  }


}
