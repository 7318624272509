<div>Some tags before</div>
<owl-carousel-o [options]="customOptions">

    <ng-template class="mx-2" carouselSlide *ngFor="let item of [1,2,3,4,5,7]">
        <ng-container class="mx-2" >
            
            <app-product-card></app-product-card>

        </ng-container>
        
    </ng-template>  

</owl-carousel-o> 
<div>Some tags after</div>