<ngx-ui-loader  bgsType="ball-spin-clockwise-fade-rotating" fgsPosition='center-center'
  bgsPosition="bottom-right"
    pbColor='#FF0000' 
  overlayColor='rgba(192,192,192,0.5)' minTime=".01" a='as' fgsColor='#FF0000' bgsColor='#FF0000'
  [fgsTemplate]="foregroundSpinner">
</ngx-ui-loader>
   <!-- fgsSize="90" bgsSize="80" pbThickness="3" -->

<ng-template #foregroundSpinner>
  <!-- Your awesome foreground spinner defined here -->
  <div class="loader"></div>
</ng-template>

<app-nav></app-nav>

<div class="sav-from-nav">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>