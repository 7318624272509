import { ApiLinks } from './../../../shared/model/ApiLinks';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductResponseModel } from '../model/ProductResponseModel';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private url = environment.url;
  private header = environment.header;
  private api = ApiLinks
  constructor(private http: HttpClient) { }

  getProductById(id :string|null) {
    // return this.http.get<FeaturedCoursesResponse>(this.url + api);
    return this.http.get<ProductResponseModel>(this.url + this.api.getProductById + id);

  }

}
