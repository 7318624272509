import { AlertService } from './../alert/alert.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FavouriteService } from './../favourite/favourite.service';
import { Product } from './../../model/ProductModel';
import { User } from './../../model/UserModel';
import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiLinks } from '../../model/ApiLinks';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  user: User
  api = ApiLinks
  url = environment.url
  products = new BehaviorSubject<Product[]>([]);

  constructor(private authService: AuthService, private http: HttpClient, private alertService: AlertService) {
    this.authService.user.subscribe(user => this.user = user);

  }

  getListOfProduct(ids) {
    let params = new HttpParams().set('product_ids', JSON.stringify(ids));

    this.http.get(this.url + this.api.productList, { params: params }).subscribe(
      (result: any) => { // get array of products  
        this.products.next(result.data);
      }, (err: any) => {
        this.alertService.serverError(err);
      }
    );
  }



}
