import { environment } from './../../../../environments/environment';
import { ApiLinks } from 'src/app/shared/model/ApiLinks';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { User } from '../../model/UserModel';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  // this.authService.user.subscribe(user => this.user = user);
  api = ApiLinks;
  url = environment
  constructor(private http: HttpClient) {
    this.initUser();
  }

  signIn() {

  }

  initUser() { // set intial value of user
    let localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      this.user.next(localUser);
    }
    else {
      this.user.next(null);
    }
  }

}
